<template>
  <div>
    <b-row>
      <b-col
        cols="4"
      >
        <b-form-file
          accept="text/csv"
          @change="handleFileUpload( $event )"
        />
      </b-col>
      <b-col
        cols="8"
      >
        <b-button
          variant="primary"
          type="button"
          @click="uploadFile"
        >
          Import
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        style="padding-top:20px;"
      >
        <b-alert
          v-if="importFinished"
          variant="primary"
          show
          style="padding:20px;"
        >
          Import Finished.
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        style="padding-top:20px;"
      >
        <vue-json-pretty
          v-if="res.length > 0"
          :data="res"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormFile,
  BAlert,
} from 'bootstrap-vue'

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

import {
  onUnmounted,
  ref,
} from '@vue/composition-api'

import store from '@/store'
import bonusStoreModule from './backofficeStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormFile,
    VueJsonPretty,
    BAlert,
  },
  setup() {
    const BONUS_STORE_MODULE = 'backoffice-store-module'
    //  Register BonusStoreModule
    if (!store.hasModule(BONUS_STORE_MODULE)) store.registerModule(BONUS_STORE_MODULE, bonusStoreModule)

    //  Unregister BonusStoreModule
    onUnmounted(() => {
      if (store.hasModule(BONUS_STORE_MODULE)) store.unregisterModule(BONUS_STORE_MODULE)
    })

    const file = ref(null)
    const handleFileUpload = e => {
      // eslint-disable-next-line prefer-destructuring
      file.value = e.target.files[0]
    }

    const res = ref([])
    const importFinished = ref(false)
    const uploadFile = () => {
      importFinished.value = false
      const formData = new FormData()
      formData.append('attachment', file.value)
      store.dispatch(`${BONUS_STORE_MODULE}/postVinCsv`, formData)
        .then(response => {
          res.value = response.data
          importFinished.value = true
        })
    }

    return {
      handleFileUpload,
      uploadFile,
      res,
      importFinished,
    }
  },
}
//
</script>
